import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../core/context/StoreContext";
import { useTranslation } from "react-i18next";
import { postLogin, setDefaultHeader } from "../../core/api/Api";
import {
  decryptString,
  deleteCookie,
  encryptString,
  getCookie,
  setCookie,
  showToast,
} from "../../core/common-service/CommonService";

export function LoginBox() {
  const { setIsLoggedIn, setUser } = useContext(StoreContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onShowHidePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onRememberMeChange = () => {
    setRememberMe((prop) => !prop);
  };

  const _postLogin = (body) => {
    postLogin(body)
      .then((res) => {
        setLoading(false);
        const status_code = res?.status;
        const response = res?.data?.success;
        if (status_code === 200) {
          const token = response?.token;
          const user = response?.user;
          if (user?.is_verified) {
            const service_session_id = response?.service_session_id ?? null;
            setDefaultHeader("Bearer " + token);
            setIsLoggedIn(true);
            setUser(user);
            setTimeout(async () => {
              if (rememberMe) {
                setCookie("token", token);
                const credentials = await encryptString(
                  JSON.stringify({ email, password }),
                );
                setCookie("credentials", JSON.stringify(credentials));
              } else {
                deleteCookie("credentials");
                sessionStorage.setItem("token", token);
              }
              if (service_session_id) {
                navigate("/questions/" + service_session_id);
                localStorage.removeItem("trial_session");
              } else {
                const from = location.state?.from || "/"; // default to home page if no previous location is found
                navigate(from, { state: location.state });
              }
            }, 300);
          } else {
            showToast({ type: "error", message: t("error.user_not_allowed") });
          }
        }
      })
      .catch((e) => {
        const response = e?.response;
        const status_code = response?.status;
        if (status_code === 400) {
          navigate("/confirm-registration", { state: { email } });
        }
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;

    // added this for auto fill from browser
    const email_input = document.getElementById("email").value ?? email;
    const password_input =
      document.getElementById("password").value ?? password;
    setLoading(true);
    const trial_session = localStorage.getItem("trial_session");
    _postLogin({ email: email_input, password: password_input, trial_session });
  };

  const onResolveGoogleLogin = ({ provider, data }) => {
    const trial_session = localStorage.getItem("trial_session");
    _postLogin({ access_token: data.access_token, trial_session });
  };

  const getCredentials = async () => {
    const credentialsCookie = getCookie("credentials");
    if (credentialsCookie) {
      const credentialsStr = await decryptString(JSON.parse(credentialsCookie));
      const credentials = JSON.parse(credentialsStr);
      setEmail(credentials?.email);
      setPassword(credentials?.password);
    }
  };

  useEffect(() => {
    try {
      getCredentials();
    } catch (e) {
      console.log("Error parsing credentials: " + e);
    }

    return () => {
    };
  }, []);

  return <div className="aai-form-wrapper">
    <div>
      <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center mb-3">
        <h2 className="aai-form-title">
          {t("login_to_your_account")}
        </h2>
      </div>
    </div>

    <div>
      <div className="row g-3">
        <div className="col-lg-12">
          <div className="aai-form-container">
            <input
              className="form-control shadow-none"
              id="email"
              name="email"
              type="email"
              placeholder={t("enter_your_email")}
              value={email}
              onChange={onEmailChange}
            />
          </div>
        </div>
        <div className="col-lg-12 mb-2">
          <div className="aai-form-container position-relative">
            <input
              className="form-control shadow-none"
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder={t("enter_your_password")}
              value={password}
              onChange={onPasswordChange}
            />
            <button
              className="aai-form-eye"
              onClick={onShowHidePassword}
            >
              <i
                className={
                  "fa-regular fa-eye" + (showPassword ? "" : "-slash")
                }
              ></i>
            </button>
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div
            className="aai-form-container d-flex gap-3 position-relative"
            onClick={onRememberMeChange}
          >
            <input
              type="checkbox"
              className="shadow-none"
              checked={rememberMe ? "checked" : ""}
              readOnly
            />
            <label className="aai-form-check-label">
              {t("remember_me")}
            </label>
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div className="d-flex justify-content-end">
            <Link
              to="/forgot-password"
              className="aai-form-support-link text-decoration-underline"
            >
              {t("forgot_password")}
            </Link>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="d-grid">
            <button
              onClick={handleSubmit}
              type="submit"
              disabled={loading}
              className="aai-btn btn-pill-solid"
            >
              {t("login")}
            </button>
          </div>
          <LoginSocialGoogle
            style={{ marginTop: 0 }}
            // isOnlyGetToken
            scope={"https://www.googleapis.com/auth/userinfo.email"}
            client_id={process.env.REACT_APP_GG_APP_ID || ""}
            onResolve={onResolveGoogleLogin}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <div className="row aai-auth-links">
              <div className="col-lg-12 mb-3 mb-lg-0">
                <Link
                  href="#"
                  className="aai-auth-link w-100 justify-content-center d-flex align-items-center"
                >
                  <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.1258 11.2139C21.1258 10.4225 21.0603 9.84497 20.9185 9.24609H11.6973V12.818H17.1099C17.0008 13.7057 16.4115 15.0425 15.102 15.9408L15.0836 16.0603L17.9992 18.2738L18.2012 18.2936C20.0563 16.6145 21.1258 14.1441 21.1258 11.2139"
                      fill="#4285F4"
                    />
                    <path
                      d="M11.6976 20.6248C14.3494 20.6248 16.5755 19.7692 18.2016 18.2934L15.1024 15.9405C14.273 16.5073 13.1599 16.903 11.6976 16.903C9.10043 16.903 6.89609 15.224 6.11031 12.9033L5.99513 12.9129L2.96347 15.2122L2.92383 15.3202C4.53888 18.4644 7.85634 20.6248 11.6976 20.6248Z"
                      fill="#34A853"
                    />
                    <path
                      d="M6.10908 12.9034C5.90174 12.3045 5.78175 11.6628 5.78175 10.9997C5.78175 10.3366 5.90174 9.695 6.09817 9.09612L6.09268 8.96857L3.02303 6.63232L2.92259 6.67914C2.25695 7.98388 1.875 9.44905 1.875 10.9997C1.875 12.5504 2.25695 14.0155 2.92259 15.3203L6.10908 12.9034"
                      fill="#FBBC05"
                    />
                    <path
                      d="M11.6977 5.09664C13.5419 5.09664 14.7859 5.87733 15.4953 6.52974L18.2671 3.8775C16.5648 2.32681 14.3494 1.375 11.6977 1.375C7.85637 1.375 4.53889 3.53526 2.92383 6.6794L6.09942 9.09638C6.89612 6.77569 9.10047 5.09664 11.6977 5.09664"
                      fill="#EB4335"
                    />
                  </svg>
                  <span>
                              {t("login")} {t("with")} Google
                            </span>
                </Link>
              </div>
            </div>
          </LoginSocialGoogle>
        </div>
        <div className="text-center mt-3">
          <p className="aai-form-support-text">
            {t("dont_have_an_account_yet")}?{" "}
            <Link
              to="/register"
              className="aai-form-support-link text-decoration-underline"
            >
              {t("signup")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>;
}
